<script>

export default {
    name: 'Login',
    mounted() {
      this.axios.post(`/api/user/activate/${this.$route.params.activeToken}`,
              {}
              , {
                headers: {'Content-Type': 'application/json'}
              }
      ).then(response => {
        // console.log('response', response);
        this.$router.replace({path: "/"});
      }).catch(error => {
        // console.log('error', error);
        // console.log('error', error.response.data);
      }).finally(() => {
        // console.log('finally');
      });
    }
  }

</script>
